<script setup>
import { object, string } from 'yup';
import { Form as VeeForm } from 'vee-validate';
import { computed } from '#imports';
import { useWaitRequest } from '@/composables/useWaitRequest';

import UiMaskInput from '@/components/ui/form/UiMaskInput.vue';
import UiButton from '@/components/ui/button/UiButton.vue';
import FormWrapper from '@/components/layout/forms/FormWrapper.vue';

const { requestInProgress, waitRequest } = useWaitRequest();

const props = defineProps({
  formData: {
    type: Object,
    default: () => ({}),
  },
  componentData: {
    type: Object,
    default: () => ({}),
  },
});

const emits = defineEmits(['update-form']);

//<editor-fold desc="Form">
const phoneData = computed({
  get() {
    return props.formData.mobilePhone;
  },
  set(detail = {}) {
    emits('update-form', { key: 'mobilePhone', value: detail.masked });
  },
});

function sendForm(values, actions) {
  waitRequest(() => props.componentData?.submitForm?.(values, actions));
}
// </editor-fold>

//<editor-fold desc="Validation rules">
const validationSchema = object({
  mobilePhone: string().required().trim().lengthPhone(11),
});
// </editor-fold>
</script>
<template>
  <FormWrapper
    class="phone-verification-form"
    heading="Восстановление пароля"
    description="Введите номер телефона, указанный при регистрации"
  >
    <template #content>
      <VeeForm
        v-slot="{ isSubmitting }"
        :validation-schema="validationSchema"
        class="phone-verification-form__form"
        @submit="sendForm"
      >
        <div class="phone-verification-form__section">
          <UiMaskInput
            v-model="phoneData"
            :mask="'+7 (###) ### ## ##'"
            unmasked
            name="mobilePhone"
            label="Телефон"
            hint="Введите номер телефона"
            class="phone-verification-form__item"
            input-mode="numeric"
          />
        </div>

        <div class="phone-verification-form__footer">
          <UiButton
            type="submit"
            class="phone-verification-form__button phone-verification-form__button--submit"
            text="Продолжить"
            :is-disabled="isSubmitting || requestInProgress"
            @dblclick.prevent
          />
        </div> </VeeForm
    ></template>
  </FormWrapper>
</template>
<style scoped lang="scss">
.phone-verification-form {
  &__footer {
    margin-top: em(48);
  }

  &__button {
    &--submit {
      min-width: em(240);
    }
  }
}
</style>
