import { defineStore } from 'pinia';
import { useRequest } from '@/composables/useRequest';

export const useRestorePasswordStore = defineStore({
  id: 'restorePassword',
  state: () => ({
    uuid: null,
    status: null,
    key: null,
  }),
  getters: {},
  actions: {
    phoneVerify(data = {}) {
      return useRequest('/restore-password/phone/verify', {
        method: 'POST',
        body: {
          mobilePhone: data.phoneData,
          captcha: data.token,
        },
      }).then(({ data }) => {
        this.uuid = data.uuid;

        return data;
      });
    },

    fetchVerifyCall() {
      return useRequest(`/restore-password/phone/verify/call/${this.uuid}`, {
        method: 'GET',
      }).then(({ data }) => {
        this.status = data.status;
        this.key = data.key;

        return data;
      });
    },

    newPassword(password = '') {
      return useRequest('/restore-password/set-new-password', {
        method: 'POST',
        body: {
          key: this.key,
          password,
        },
      });
    },

    codeVerify(code = '') {
      return useRequest('/restore-password/phone/verify/code', {
        method: 'POST',
        body: {
          code,
          uuid: this.uuid,
        },
      }).then(({ data }) => {
        this.status = data.status;
        this.key = data.key;

        return data;
      });
    },
  },
});
